<template>
  <div class="cart">
    <v-container>
      <v-card>
        <v-card-title>
          <h3><b> Winkelwagen</b></h3>

          <v-spacer></v-spacer>
          <v-btn @click="shopping()" color="primary" style="float:right">
            Verder winkelen
          </v-btn>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="selectedKavels"
          :search="search"
          disable-pagination
          :hide-default-footer="true"
          class="elevation-1"
        >
          <template v-slot:top> </template>
          <template v-slot:[`item.price`]="{ value, item }">
            €{{ item.promotionPrice ? item.promotionPrice : value }}
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-btn icon @click="removeFromCart(item.kavelnr)">
              <v-icon style="color:red">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <table
          style="float: right; text-align: right; border-collapse: collapse;"
          border="1"
        >
          <tr>
            <td style="padding: 8px; border: 1px solid black;">Subtotaal:</td>
            <td style="padding: 8px; border: 1px solid black;">
              €{{ totalAmount }}
            </td>
          </tr>
          <template v-if="discountAmount > 0">
            <tr>
              <td style="padding: 8px; border: 1px solid black; color: red;">
                Korting:
              </td>
              <td style="padding: 8px; border: 1px solid black; color: red;">
                -%{{ discountAmount }}
              </td>
            </tr>
          </template>
          <tr>
            <td style="padding: 8px; border: 1px solid black;">
              <b>Totaal:</b>
            </td>
            <td style="padding: 8px; border: 1px solid black;">
              <b>€{{ totalAmount - (totalAmount * discountAmount / 100) }}</b>

            </td>
          </tr>
        </table>
      </v-card>

      <v-row style="padding-top:5rem">
        <v-col cols="12" md="7">
          <v-row>
            <v-col cols="12">
              <v-radio-group row>
                <v-radio label="Mevr."></v-radio>
                <v-radio label="Dhr."></v-radio>
                <v-radio label="Fam."></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="voorletters"
                outlined
                label="Voorl/z *"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="achternaam"
                outlined
                label="Achternaam *"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="email"
                outlined
                label="Email *"
                :disabled="isEmailDisabled"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="adres"
                outlined
                label="Adres + huisnummer *"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="postcode"
                outlined
                label="Postcode *"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="woonplaats"
                outlined
                label="Woonplaats *"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="land"
                outlined
                label="Land"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="telefoon"
                outlined
                label="Telefoon"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                dense
                v-model="opmerkingen"
                outlined
                label="Opmerkingen"
              ></v-textarea>
            </v-col>

            <v-col cols="12">
              <div
                style="display: flex; align-items: center; flex-wrap: nowrap;"
              >
                <v-text-field
                  v-model="discountCode"
                  label="Kortingscode"
                  outlined
                  dense
                  placeholder="Voer kortingscode in"
                  style="max-width: 250px; height: 36px; margin-right: 10px;"
                ></v-text-field>
                <v-btn
                  color="primary"
                  @click="applyDiscount"
                  style="min-width: auto;"
                >
                  Toepassen
                </v-btn>
              </div>
            </v-col>

            <v-row>
              <v-col cols="12">
                <h5><b>Kies een betaalmethode:</b></h5>
                <v-radio-group v-model="selectedPaymentMethod">
                  <v-radio
                    label="Per factuur betalen"
                    value="per_factuur"
                  ></v-radio>
                  <v-radio
                    label="In de winkel betalen"
                    value="in_de_winkel"
                  ></v-radio>
                  <v-radio label="Online betalen" value="online"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-col cols="12">
              <v-btn type="submit" color="primary" style="margin:2px"
                >Annuleer</v-btn
              >
              <v-btn color="primary" @click="placeOrder()"
                >Plaats definitieve bestelling</v-btn
              >
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="12"
          md="5"
          class="hidden-sm-and-down"
          style="padding-top:7rem"
        >
          <h5><b>HOE BESTEL IK?</b></h5>
          <p>
            U dient eerst uw gegevens in te vullen. De velden gemarkeerd met een
            sterretje (*) zijn verplicht.
          </p>
          <p>
            LET OP: ALS BIJ HET BESTELLEN IETS FOUT GAAT, WORDT HIER BOVEN EEN
            OF MEERDERE FOUTBOODSCHAPPEN IN HET ROOD WEERGEGEVEN. Na correctie
            kan u het opnieuw proberen.Lukt het niet dan kan u uw bestelling
            telefonisch 070 - 362 52 63 tijdens kantooruren) of per email:
            admin@postzegels.com aan ons doorgeven.
          </p>
          <p>
            Als u vaker bij ons, VIA DE WEBSITE, besteld heeft dan kunt u uw
            gegevens opvragen door (na uw emailadres ingevuld te hebben) op de
            knop 'opvragen gegevens' te klikken, anders dient u alle gegevens
            voorzien van een * zelf correct in te vullen.
          </p>
          <p>
            U bestelt door op de knop 'Definitieve bestelling' te klikken. U
            ontvangt binnen enkele minuten een bestel-bevestiging per email. De
            bestelling wordt definitief als u van PPC een orderbevestiging
            krijgt.. Meestal binnen een werkdag.
          </p>
          <p>
            Na ontvangst van betaling wordt dan binnen een werkdag de bestelling
            naar u verzonden. Voor meer informatie over verzending en
            verzendkosten click op het menu-item 'Bestelprocedure'.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import apiKavel from "./apiKavel";
export default {
  data: () => ({
    discountAmount: 0,
    discountCode: "",
    paymentMethods: ["Per factuur", "In de winkel", "Online"],
    selectedPaymentMethod: "",
    customerCheck: false,
    search: "",
    checkEmail: "",
    title: "",
    email: "",
    voorletters: "",
    achternaam: "",
    adres: "",
    postcode: "",
    woonplaats: "",
    land: "",
    telefoon: "",
    opmerkingen: "",
    headers: [
      {
        text: "Kavel nummer",
        value: "kavelnr",
        sortable: true,
      },

      {
        text: "Land",
        value: "land.Ned",
        sortable: true,
      },

      {
        text: "Emballage",
        value: "txtNed",
        sortable: true,
      },

      {
        text: "Prijs",
        value: "price",
        sortable: true,
        formatter: (value) => `€${value}`,
      },
      {
        text: "",
        value: "Actions",
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapState(["selectedKavels"]),
    totalAmount() {
      return this.selectedKavels
        .reduce(
          (total, item) => total + Number(item.promotionPrice ?? item.price),
          0
        )
        .toFixed(2);
    },
    isEmailDisabled() {
      return Boolean(this.checkEmail);
    },
  },

  methods: {
    shopping() {
      this.$router.push("/");
    },
    async applyDiscount() {
      if (!this.discountCode) {
        this.$swal.fire("Fout", "Voer een kortingscode in.", "error");
        return;
      }

      try {
        const response = await apiKavel.validateDiscountCode({
          code: this.discountCode,
        });
        if (response.valid) {
          this.discountAmount = response.discountAmount; // Sla de korting op
          this.$swal.fire(
            "Succes",
            `Kortingscode toegepast. Je krijgt €${this.discountAmount} korting!`,
            "success"
          );
        } else {
          this.$swal.fire("Fout", "Ongeldige kortingscode.", "error");
        }
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Fout",
          "Er is een fout opgetreden bij het toepassen van de kortingscode.",
          "error"
        );
      }
    },
    removeFromCart(itemId) {
      const index = this.selectedKavels.findIndex(
        (kavel) => kavel.kavelnr === itemId
      );
      if (index !== -1) {
        this.selectedKavels.splice(index, 1);
      }
    },
    validateFormData() {
      const requiredFields = [
        "voorletters",
        "achternaam",
        "email",
        "adres",
        "postcode",
        "woonplaats",
      ];
      for (const field of requiredFields) {
        if (!this[field]) {
          this.$swal.fire(
            "Ontbrekende informatie",
            `Vul alstublieft het veld ${field} in.`,
            "error"
          );
          return false;
        }
      }
      return true;
    },

    async placeOrder() {
      if (!this.validateFormData()) {
        return;
      }
      if (this.selectedPaymentMethod === "") {
        this.$swal.fire(
          "Fout",
          "Selecteer een betaalmethode voordat u de bestelling plaatst.",
          "error"
        );
        return;
      }

      if (this.selectedPaymentMethod === "per_factuur") {
        const paymentData = {
          initials: this.voorletters,
          name: this.achternaam,
          phoneNumber: this.telefoon,
          adres: this.adres,
          city: this.woonplaats,
          land: this.land,
          postcode: this.postcode,
          email: this.email,
          discountCode: this.discountCode,
          paymentMethod: "Per factuur",
          selectedKavels: this.selectedKavels,
        };

        try {
          await apiKavel.shopOrder(paymentData);
          this.$swal.fire(
            "Bestelling geplaatst",
            "Uw bestelling is succesvol geplaatst.",
            "success"
          );
          this.selectedKavels.splice(0, this.selectedKavels.length);
          this.$router.push("/confirmation");
        } catch (error) {
          console.error(error);
          this.$swal.fire(
            "Fout bij betaling",
            "Er is een fout opgetreden bij de betaling. Probeer het opnieuw.",
            "error"
          );
        }
      }
      if (this.selectedPaymentMethod === "in_de_winkel") {
        const paymentData = {
          initials: this.voorletters,
          name: this.achternaam,
          phoneNumber: this.telefoon,
          adres: this.adres,
          city: this.woonplaats,
          land: this.land,
          postcode: this.postcode,
          discountCode: this.discountCode,
          email: this.email,
          paymentMethod: "In de winkel",
          selectedKavels: this.selectedKavels,
        };
        try {
          await apiKavel.shopOrder(paymentData);
          this.$swal.fire(
            "Bestelling geplaatst",
            "Uw bestelling is succesvol geplaatst.",
            "success"
          );
          this.$router.push("/confirmation");
        } catch (error) {
          console.error(error);
          this.$swal.fire(
            "Fout bij betaling",
            "Er is een fout opgetreden bij de betaling. Probeer het opnieuw.",
            "error"
          );
        }
      }

      if (this.selectedPaymentMethod === "online") {
        const paymentData = {
          initials: this.voorletters,
          name: this.achternaam,
          phoneNumber: this.telefoon,
          adres: this.adres,
          city: this.woonplaats,
          land: this.land,
          discountCode: this.discountCode,
          postcode: this.postcode,
          currency: "EUR",
          email: this.email,
          paymentMethod: "Online",
          selectedKavels: this.selectedKavels,
        };

        try {
          const response = await apiKavel.paymentOrder(paymentData);
          window.location.href = response.url;
        } catch (error) {
          console.error(error);
          this.$swal.fire(
            "Fout bij betaling",
            "Er is een fout opgetreden bij de betaling. Probeer het opnieuw.",
            "error"
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.cart p {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 12px;
}
.textarea-wrapper {
  display: flex;
  align-items: center;
}

.textarea-wrapper label {
  margin-right: 10px;
}

.strike {
  text-decoration: line-through;
}
</style>
